import React, { useEffect, useState } from "react"
import styled from 'styled-components'
import { space, layout, grid, fontSize } from 'styled-system'
import { Link } from "gatsby"

import { withTranslate } from '../i18n/withTranslate'
import LanguageMenu from '../components/LanguageMenu'
import Logo from "../../public/images/logo.svg"

const Box = styled.div`
  display: flex;
  margin: 40px auto 0;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  max-width: 1600px;
  overflow: hidden;

  ${space}
`
const Side = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  background: #fff;
  z-index: 100;
  transition: all 0.4s cubic-bezier(0.33, 1, 0.68, 1);

  opacity: ${({ isOpen }) => isOpen ? 1 : .8};
  visibility: ${({ isOpen }) => isOpen ? 'visible' : 'hidden'};
  transform: ${({ isOpen }) => isOpen ? 'translate3d(0px, 0px, 0px)' : 'translate3d(100%, 0px, 0px)'};

  ${layout}
`
const Toggle = styled.div`
  position: relative;
  z-index: 101;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  display: grid;
  grid-template-rows: repeat(8, 1fr);
  align-items: center;
  transition: all 0.4s .1s cubic-bezier(0.33, 1, 0.68, 1);

  background: ${({ isOpen }) => isOpen ? '#3864f130' : '#fff'};
`
const Mask = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  z-index: 99;
  background: #3864f130;
  transition: all 0.4s .1s cubic-bezier(0.33, 1, 0.68, 1);

  opacity: ${({ isOpen }) => isOpen ? 1 : 0};
  width: ${({ isOpen }) => isOpen ? '100vw' : '0vw'};
`
const Line = styled.div`
  width: 22px;
  height: 2px;
  background: #2950cc;
  margin: 0 auto;
  transition: all 0.4s .1s cubic-bezier(0.33, 1, 0.68, 1);

  transform: ${({ position, isOpen }) => !isOpen ? '' : position === 1 ?
    'translate3d(0px, 3px, 0px) rotate3d(0, 0, 1, 45deg);'
    :
    'translate3d(0px, -3px, 0px) rotate3d(0, 0, 1, -45deg)'
  };

  ${grid}
`
const DesktopMenu = styled.div`
  display: flex;
  align-items: center;

  ${layout}
`
const MobileMenu = styled.div`
  ${layout}
`
const Menu = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  height: 50%;
  /* justify-content: center; */
  margin: 40px;

  ${space}
`
const LogoWrapper = styled(Link)`
  display: flex;

  svg {
    width: 100%;
    height: auto;
  }

  ${layout}
  ${space}
`
const LanguageWrapper = styled.div`
  transition: all 0.4s .3s cubic-bezier(0.33, 1, 0.68, 1);

  opacity: ${({ isOpen }) => isOpen ? 1 : 0};
  transform: ${({ isOpen }) => isOpen ? 'translate3d(0px, 0px, 0px)' : 'translate3d(40px, 0px, 0px)'};
`
const MobileItem = styled.a`
  font-weight: 500;
  color: ${({ theme }) => theme.colors.blue};
  text-decoration: none;
  margin-bottom: 50px;
  transition: all 0.4s .2s cubic-bezier(0.33, 1, 0.68, 1);

  opacity: ${({ isOpen }) => isOpen ? 1 : 0};
  transform: ${({ isOpen }) => isOpen ? 'translate3d(0px, 0px, 0px)' : 'translate3d(40px, 0px, 0px)'};

  ${fontSize}
  ${space}
`
const DesktopItem = styled.a`
  font-weight: 500;
  color: ${props => props.theme.colors.gray};
  text-decoration: none;
  margin-right: 40px;
`

const Nav = ({ t }) => {
  const [email, setEmail] = useState('')
  const [isOpen, setIsOpen] = useState(false)

  const handleClick = (e) => {
    setIsOpen(!isOpen)
  }

  useEffect(() => {
    setEmail('contact@socieci.com')
  }, [])

  return (
    <Box
      pt={['0px', '10px', '20px', '30px']}
      mt={['20px', '20px', '40px', '40px']}
      pb={['20px', '20px', '20px', '20px']}
      px={['20px', '20px', '30px', '40px']}
    >
      <LogoWrapper to="/"
        width={['100px', '100px', '120px', '160px']}
      >
        <Logo />
      </LogoWrapper>

      <DesktopMenu display={[' none', 'none', 'flex', 'flex']}>
        <DesktopItem href={`mailto:${email}`}>
          {t('contact')}
        </DesktopItem>

        <LanguageMenu />
      </DesktopMenu>

      <MobileMenu display={[' block', 'block', 'none', 'none']}>
        <Side
          isOpen={isOpen}
          width={['100vw', '100vw', '400px', '400px']}
        >
          <Menu
            pt={['20px', '30px', '40px', '50px']}
            mt={['20px', '20px', '40px', '40px']}
          >
            <LanguageWrapper isOpen={isOpen} >
              <LanguageMenu doOnClick={(e) => handleClick(e)} />
            </LanguageWrapper>

            <MobileItem 
              isOpen={isOpen} 
              href={`mailto:${email}`}
              fontSize={['20px', '25px', '25px', '25px']}
              mt={'80px'}
            >
              {t('contact')}
            </MobileItem>
          </Menu>
        </Side>

        <Mask isOpen={isOpen} onClick={(e) => handleClick(e)} />

        <Toggle isOpen={isOpen} onClick={(e) => handleClick(e)}>
          <Line position={1} isOpen={isOpen} gridRow={4} />
          <Line position={2} isOpen={isOpen} gridRow={5} />
        </Toggle>
      </MobileMenu>
    </Box>
  )
}

export default withTranslate(Nav)
