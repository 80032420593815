import React from "react"
import styled from 'styled-components'
import { layout, space, fontSize } from 'styled-system'
import { withTranslate } from '../i18n/withTranslate'

const Wrapper = styled.div`
  max-width: 1600px;
  margin: 0 auto;

  ${space}
`
const ClientHeading = styled.h2`
  color: ${({ theme }) => theme.colors.blue};
  text-align: center;

  ${space}
  ${fontSize}
`
const ClientList = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  ${space}
`
const Client = styled.img`
  align-self: center;
  max-width: 200px;
  height: auto;
  
  ${layout}
  ${space}
`

const Clients = ({ t }) => {
  return (
    <Wrapper mb={['80px', '80px', '100px', '100px']}>
      <ClientHeading fontSize={[13, 13, 16, 20]} my={['20px', '20px', '20px', '20px']}>
        {t('weServedThem')}
      </ClientHeading>

      <ClientList
        mx={['20px', '20px', '0px', '0px']}
      >
        <Client
          width={['100px', '120px', '150px', '200px']}
          mx={['10px', '20px', '30px', '0px']}
          my={['20px', '20px', '0px', '0px']}
          src="images/clients/bouygues.png"
          alt="bouygues"
        />
        <Client
          width={['100px', '120px', '150px', '200px']}
          mx={['10px', '20px', '30px', '0px']}
          src="images/clients/lafarge.png"
          alt="lafarge"
        />
        <Client
          width={['100px', '120px', '150px', '200px']}
          mx={['10px', '20px', '30px', '0px']}
          src="images/clients/bernabe.png"
          alt="bernabe"
        />
        <Client
          width={['100px', '120px', '150px', '200px']}
          mx={['10px', '20px', '30px', '0px']}
          src="images/clients/texaco.png"
          alt="texaco"
        />
        <Client
          width={['100px', '120px', '150px', '200px']}
          mx={['10px', '20px', '30px', '0px']}
          src="images/clients/carena.png"
          alt="carena"
        />
      </ClientList>
    </Wrapper>
  )
}

export default withTranslate(Clients)
