import React from "react"
import styled from 'styled-components'
import { fontSize, layout, space } from 'styled-system'

const Title = styled.h1`
  width: 100%;
  text-align: center;
  font-weight: 600;
  color: ${props => props.theme.colors.blue};

  ${fontSize}
  ${layout}
  ${space}
`

export default function Heading({ children, ...props }) {
  return (
    <Title
      fontSize={[20, 26, 32, 40]}
      py={['30px', '30px', '30px', '60px']}
      {...props}
    >
      {children}
    </Title>
  )
};
