export const isInViewport = function (el) {
  const rect = el.getBoundingClientRect()

  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom >= 0 &&
    rect.right >= 0
    // rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    // rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  )
}

// function getTransformValues(element){
//   return element.style.transform.replace(/[^0-9\-.,]/g, '').split(',')
//   // var x = matrix[12] || matrix[4]
//   // var y = matrix[13] || matrix[5]
// }

export const parallax = function (element,
  {
    speed = .3,
    direction = "top",
    reverse = false,
    wrapper = null,
  } = {}
) {
  // console.log('parallax element: ', element)
  if (!element) return
  let way = reverse ? -1 : 1;

  const getTransform = (value) => {
    if (direction === "left")
      return `translate3d(${way * value}px, 0px, 0px)`

    return `translate3d(0px, ${way * value}px, 0px)`
  }

  let elementToCheck = element

  if (wrapper) {
    elementToCheck = wrapper
  }

  const rectInit = elementToCheck.getBoundingClientRect()
  element.style.transform = getTransform(rectInit.top * speed)

  window.addEventListener("scroll", function () {
    const rect = elementToCheck.getBoundingClientRect()
    const isInView = rect.top + rect.height >= 0 && rect.bottom >= 0

    if (isInView) {
      element.style.transform = getTransform(rect.top * speed)
    }
  })
}
