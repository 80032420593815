import React/* , { useState } */ from "react"
import { useTranslation } from "react-i18next"
import styled from 'styled-components'
import { Link } from "gatsby"

// import FrLogo from "../../public/images/flags/fr.svg"
// import EnLogo from "../../public/images/flags/en.svg"

const MenuItem = styled(Link)`
  svg {
    width: 30px;
    height: 20px;
  }
`
const Wrapper = styled.div`
  width: 30px;
  height: 20px;
`

const LanguageMenu = ({ doOnClick }) => {
  const { i18n } = useTranslation()

  const currentLanguage = i18n.language.slice(0, 2).toLocaleLowerCase()
  // const [language, setLanguage] = useState(currentLanguage)

  function handleClick(lang) {
    // i18n.changeLanguage(lang)
    // setLanguage(lang)

    // if (doOnClick) doOnClick()
  }

  return (
    <Wrapper>
      {currentLanguage === 'en' ?
        <MenuItem onClick={() => handleClick('fr')} to="/fr">
          <svg key="svg-fr" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300 200"><path fill="#2950CC" d="M0 0h100v200H0z" /><path fill="#FFF" d="M100 0h100v200H100z" /><path fill="#EF3349" d="M200 0h100v200H200z" /></svg>
        </MenuItem>
        :
        <MenuItem onClick={() => handleClick('en')} to="/en">
          {/* <EnLogo /> */}
          <svg key="svg-en" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300 200"><path fill="#FFF" d="M.001 0h299.843v199.977H.001z" /><path fill="#EF3349" d="M134.928 119.926v80.051h29.988v-80.051h134.928V79.947H164.916V0h-29.988v79.947H0v39.979z" /><path fill="#2950CC" d="M174.915 64.733V0h97.08zM174.915 135.244v64.733h97.08zM124.928 135.244v64.733H27.849zM124.928 64.733V0H27.849zM.001 18.589v48.068h72.087zM299.843 18.589v48.068h-72.087zM299.843 181.387V133.32h-72.087zM.001 181.387V133.32h72.087z" /><path fill="#EF3349" d="M299.843 0h-16.654L183.4 66.657h16.654L300 0M116.443 133.316H99.789L0 199.974h16.654l99.946-66.658M83.268 66.709h16.653L.001 0 0 11.18zM216.523 133.291h-16.654L299.79 200v-11.18z" /></svg>
        </MenuItem>
      }
    </Wrapper>
  )
}

export default LanguageMenu