import React, { useRef, useEffect } from "react"
import styled from 'styled-components'
import { fontSize, layout, space } from 'styled-system'

const Name = styled.h3`
  width: 100%;
  text-align: center;
  font-weight: 600;
  color: ${({ color, theme }) => color || theme.colors.black};
  transition: all 0.3s ease;
  transform: scale3d(1, 1, 1);

  ${fontSize}
  ${layout}
  ${space}
`
const Image = styled.img`
  width: 100%;
  transition: all 0.3s ease;
  transform: scale3d(1, 1, 1);

  ${space}
`
const Wrapper = styled.div`
  background: #eaedfa;
  overflow: hidden;
  background: ${({ backColor }) => backColor || '#f5f5f5'};
  transition: all 0.3s ease;
  transform: scale3d(1, 1, 1);

  &:hover {
    transform: scale3d(0.92, 0.92, 0.92);
  }

  &:hover ${Image} {
    transform: scale3d(1.1, 1.1, 1.1);
  }

  &:hover ${Name} {
    transform: scale3d(1.2, 1.2, 1.2);
  }

  ${fontSize}
  ${layout}
  ${space}
`

export default function Product({
  image,
  name,
  color,
  backColor,
}) {
  const imageRef = useRef()

  useEffect(() => {
  }, [])

  return (
    <Wrapper
      backColor={backColor}
    >
      <Image
        ref={imageRef}
        src={image}
      />
      <Name
        color={color}
        fontSize={[9, 13, 16, 20]}
        px={['10px', '0', '0', '0']}
        mt={['12px', '13px', '10px', '30px']}
        mb={['20px', '20px', '20px', '40px']}
      >
        {name}
      </Name>

    </Wrapper>
  )
};
