import React from "react"
import styled from 'styled-components'
import { grid, space } from 'styled-system'

const Wrapper = styled.div`
  display: grid;
  grid-gap: 40px;
  grid-auto-flow: row;
  max-width: 1600px;
  margin: 0 auto;

  @media (max-width: 700px) {

  }

  ${grid}
  ${space}
`

export default function Products({ children, ...props }) {
  return (
    <Wrapper
      px={['20px', '20px', '30px', '40px']}
      gridTemplateColumns={['repeat(2, 1fr)', 'repeat(2, 1fr)', 'repeat(3, 1fr)', 'repeat(3, 1fr)']}
      gridGap={['20px', '20px', '30px', '40px']}
      {...props}
    >
      {children}
    </Wrapper>
  )
};
