import i18next  from "i18next"
import LanguageDetector from "i18next-browser-languagedetector"

i18next
  .use(LanguageDetector)
  .init({
    fallbackLng: "en",
    resources: {
      en: {
        translations: require('../locales/en/translations.json')
      },
      fr: {
        translations: require('../locales/fr/translations.json')
      }
    },
    // have a common namespace used around the full app
    ns: ["translations"],
    defaultNS: "translations",

    debug: process.env.NODE_ENV === 'development',

    interpolation: {
      escapeValue: false, // not needed for react!!
    },

    react: {
      wait: true,
    },
  })

i18next.languages = ['en', 'fr']

export default i18next 