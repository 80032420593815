import React from "react"
import styled from 'styled-components'
import { fontSize } from 'styled-system'

const SubTitle = styled.span`
  width: 100%;
  text-align: center;
  font-weight: 400;
  color: ${props => props.theme.colors.gray};
  display: block;
  
  ${fontSize}
`

export default function SubHeading({ children, ...props }) {
  return <SubTitle fontSize={[9, 9, 12, 15]} {...props}>{children}</SubTitle>
};
