import React, { useEffect, useState } from "react"
import styled from 'styled-components'
import { fontSize, layout, space } from 'styled-system'
import { withTranslate } from '../i18n/withTranslate'

const Text = styled.p`
  font-weight: 600;
  color: ${props => props.theme.colors.blue};

  ${fontSize}
  ${layout}
  ${space}
`
const Footer = styled.footer`
  max-width: 1600px;
  margin: 0 auto;

  ${layout}
  ${space}
`
const Email = styled.a`
  font-weight: 500;
  color: ${props => props.theme.colors.orange};
  text-decoration: none;
  display: block;

  ${fontSize}
  ${space}
`
const SubEmail = styled.span`
  font-weight: 500;
  font-size: 1em;
  color: ${props => props.theme.colors.gray};
  text-decoration: none;
  display: block;

  ${fontSize}
`
const Copyright = styled.span`
  font-weight: 500;
  color: ${props => props.theme.colors.gray};
  text-decoration: none;
  display: block;

  ${fontSize}
  ${space}
`

const Foot = ({ t, ...props }) => {
  const [email, setEmail] = useState('');

  useEffect(() => {
    setEmail('contact@socieci.com')
  }, [])

  return (
    <Footer
      py={['60px', '60px', '80px', '100px']}
      px={['20px', '20px', '30px', '40px']}
    >
      <Text
        fontSize={[16, 20, 22, 24]}
        width={['90%', '80%', '70%', '50%']}
        py={['30px', '30px', '30px', '60px']}
        {...props}
      >
        {t('emailUs')}
      </Text>

      <Email
        href={`mailto:${email}`}
        fontSize={[16, 20, 22, 24]}
        mt={['40px', '60px', '80px', '100px']}
        py={'10px'}
      >
        {email}
      </Email>

      < SubEmail
        fontSize={[11, 13, 14, 15]}
      >
        {t('gettingBack')}
      </SubEmail>

      < Copyright
        fontSize={[9, 10, 12, 12]}
        pt={['120px', '150px', '150px', '200px']}
      >
        © {new Date().getFullYear()} SOCIECI & CIE SARL
      </Copyright>
    </Footer>
  )
}

  export default withTranslate(Foot)


