export default {
  colors: {
    black: '#121419',
    white: '#fff',
    blue: '#2950cc',
    gray: '#949494',
    orange: '#f9653d',
  },
  // fontSizes: [12, 14, 16, 20],
}
